import * as React from "react"
import Seo from "../components/seo"

const InternalServerError = () => (
  <>
    <Seo title="500: Internal Server Error" />
    <h1 style={{ fontSize: "36px" }}>500: Internal Server Error</h1>
  </>
)

export default InternalServerError
