/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ogImage from "../images/og-image.jpg"
import { useIntl } from "gatsby-plugin-react-intl"

function Seo({
  description,
  keywords,
  meta,
  title,
  offerOgImage,
  offerDescription,
  renderSchemaLd = false,
  robots = 'index,follow'
}) {
  const { site, contentfulMeta } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        contentfulMeta(node_locale: { eq: "pl-PL" }) {
          author
          description {
            description
          }
          keywords
          title
        }
      }
    `
  )
  const intl = useIntl()
  const lang = intl.locale

  const metaDescription =
    offerDescription || description || contentfulMeta?.description?.description
  const defaultTitle = contentfulMeta?.title
  const metaKeywords = keywords || contentfulMeta?.keywords
  const initialOgImage = offerOgImage || ogImage
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'robots',
          content: robots,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: contentfulMeta?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:image`,
          content: initialOgImage,
        },
      ].concat(meta)}
    >
      {renderSchemaLd && (
        <script type="application/ld+json">
          {`
[{
"@context": "https://schema.org",
"@type": "FAQPage",
"mainEntity": [
{
"@type": "Question",
"name": "Na co zwrócić uwagę podczas wyboru biura nieruchomości?",
"acceptedAnswer": {
 "@type": "Answer",
 "text": "Jeżeli decydujesz się na sprzedaż domu czy mieszkania przez biuro nieruchomości, powinieneś zweryfikować jego wiarygodność - najlepiej na podstawie opinii jego klientów. Zawsze warto dokładnie wczytać się w umowę z biurem i przeanalizować jej zapisy (szczególnie dotyczące ponoszonych opłat).Biura nieruchomości oferują również szereg usług dodatkowych, dzięki którym możesz zaoszczędzić czas. Są to m.in. sesja zdjęciowa nieruchomości czy doradztwo kredytowe."
}
},
{
"@type": "Question",
"name": "Jak wygląda proces sprzedaży nieruchomości przez biuro?",
"acceptedAnswer": {
 "@type": "Answer",
 "text": "Proces sprzedaży nieruchomości obejmuje kilka etapów.1. Nawiązanie współpracy z AgentemRozpoczęcie współpracy rozpoczyna się zawsze spotkaniem z Agentem biura i podpisaniem umowy.2. Przygotowanie nieruchomościNastępnie przygotowujemy lokal do sprzedaży. Dodatkowo wykonujemy jego profesjonalną sesję zdjęciową, wcześniej przygotowując nieruchomość, by wyglądała jak najkorzystniej.3. Działania okołosprzedażoweDalsza część procesu nie wymaga żadnych dodatkowych działań ze strony klienta - wszystko jest po stronie biura. Są to działania promocyjne, prowadzenie prezentacji nieruchomości, a także negocjacje cenowe.4. Sprzedaż nieruchomościOstatnim etapem jest już finalizacja sprzedaży, podpisanie umowy i przekazanie nieruchomości nabywcom."
}
}
]
}, {
"@context": "https://schema.org",
"@type": "RealEstateAgent",
"name": "DĘBOSZ Biuro Nieruchomości Niepołomice",
"image": "https://debosz.com.pl/static/logo-8836e57eec246aa0c41a088040aec6db.png",
"@id": "",
"url": "https://debosz.com.pl/kontakt/niepolomice/",
"telephone": "",
"address": {
"@type": "PostalAddress",
"streetAddress": "Rynek 12/3",
"addressLocality": "Niepołomice",
"postalCode": "32-005",
"addressCountry": "PL"
},
"geo": {
"@type": "GeoCoordinates",
"latitude": 50.0330212,
"longitude": 20.2179522
},
"openingHoursSpecification": [{
"@type": "OpeningHoursSpecification",
"dayOfWeek": [
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday"
],
"opens": "10:00",
"closes": "17:00"
},{
"@type": "OpeningHoursSpecification",
"dayOfWeek": "Saturday",
"opens": "10:00",
"closes": "14:00"
}],
"sameAs": [
"https://www.facebook.com/Debosz.Niepolomice/",
"https://instagram.com/debosz_nieruchomosci",
"https://www.youtube.com/channel/UCdRM-wbNa9tBPznLLLOSvDQ"
]
}]`}
        </script>
      )}

      <link
        rel="alternate"
        hrefLang="x-default"
        href={
          lang === "pl" ? "https://debosz.com.pl/" : "https://debosz.com.pl/en/"
        }
      />
      <link rel="alternate" hrefLang="en" href="https://debosz.com.pl/en/" />
      <link rel="alternate" hrefLang="en" href="https://debosz.com.pl/en/" />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
